import groq from 'groq'

import { frameBuilderDefaultFrameObjectFragment } from './default-frame'
import {
  frameBuilderGlassObjectFragment,
  frameBuilderProductGlassObjectFragment,
} from './glass'
import {
  frameBuilderPassepartoutColorObjectFragment,
  frameBuilderPassepartoutSizeObjectFragment,
  frameBuilderProductPassepartoutColorObjectFragment,
  frameBuilderProductPassepartoutSizeObjectFragment,
} from './passepartout'
import {
  frameBuilderProductProfileObjectFragment,
  frameBuilderProfileObjectFragment,
} from './profile'
import {
  frameBuilderProductProfileTextureObjectFragment,
  frameBuilderProfileTextureObjectFragment,
} from './profile-texture'
import { frameBuilderProductSettingsObjectFragment } from './settings'
import {
  frameBuilderProductServicePricesObjectFragment,
  frameBuilderServicePricesObjectFragment,
} from './service-prices'
import {
  frameBuilderProductSpacerColorObjectFragment,
  frameBuilderProductSpacerSizeObjectFragment,
  frameBuilderSpacerColorObjectFragment,
  frameBuilderSpacerSizeObjectFragment,
} from './spacer'
import { frameBuilderStandardSizeObjectFragment } from './standard-size'
import { frameBuilderStringsObjectFragment } from './strings'
import {
  frameBuilderProductTextureObjectFragment,
  frameBuilderTextureObjectFragment,
} from './texture'

export const frameBuilderModuleFragment = groq`
  updateHash,
  showProgressBar,
  ${frameBuilderStringsObjectFragment}
  ${frameBuilderDefaultFrameObjectFragment}
  ${frameBuilderServicePricesObjectFragment}
  ${frameBuilderProfileObjectFragment}
  ${frameBuilderTextureObjectFragment}
  ${frameBuilderProfileTextureObjectFragment}
  ${frameBuilderPassepartoutSizeObjectFragment}
  ${frameBuilderPassepartoutColorObjectFragment}
  ${frameBuilderGlassObjectFragment}
  ${frameBuilderSpacerSizeObjectFragment}
  ${frameBuilderSpacerColorObjectFragment}
  ${frameBuilderStandardSizeObjectFragment}
`

export const frameBuilderQuery = groq`
  {
    ${frameBuilderStringsObjectFragment}
    ${frameBuilderDefaultFrameObjectFragment}
    ${frameBuilderServicePricesObjectFragment}
    ${frameBuilderProfileObjectFragment}
    ${frameBuilderTextureObjectFragment}
    ${frameBuilderProfileTextureObjectFragment}
    ${frameBuilderPassepartoutSizeObjectFragment}
    ${frameBuilderPassepartoutColorObjectFragment}
    ${frameBuilderGlassObjectFragment}
    ${frameBuilderSpacerSizeObjectFragment}
    ${frameBuilderSpacerColorObjectFragment}
    ${frameBuilderStandardSizeObjectFragment}
  }
`

export const frameBuilderProductQuery = groq`
  {
    ${frameBuilderProductSettingsObjectFragment}
    ${frameBuilderProductServicePricesObjectFragment}
    ${frameBuilderProductProfileObjectFragment}
    ${frameBuilderProductTextureObjectFragment}
    ${frameBuilderProductProfileTextureObjectFragment}
    ${frameBuilderProductPassepartoutSizeObjectFragment}
    ${frameBuilderProductPassepartoutColorObjectFragment}
    ${frameBuilderProductGlassObjectFragment}
    ${frameBuilderProductSpacerSizeObjectFragment}
    ${frameBuilderProductSpacerColorObjectFragment}
  }
`
