import groq from 'groq'

import { frameBuilderImageFragment } from './image'

export const frameBuilderProfileTextureFragment = groq`
  ...,
  gallery[] {
    ...,
    image {
      ${frameBuilderImageFragment}
    },
  },
`

export const frameBuilderProfileTextureObjectFragment = groq`
  "profileTextures": *[
    _type == "frameBuilderProfileTexture" &&
    locale == $locale &&
    deactivate != true
  ] {
    ${frameBuilderProfileTextureFragment}
  },
`

export const frameBuilderProductProfileTextureObjectFragment = groq`
  "profileTextures": *[
    _type == "frameBuilderProfileTexture" &&
    locale == $locale &&
    deactivate != true
  ] {
    ${frameBuilderProfileTextureFragment}
  },
`
