import groq from 'groq'

import { frameBuilderImageFragment } from './image'
import { frameBuilderTextureFragment } from './texture'

export const frameBuilderProfileFragment = groq`
  ...,
  icon {
    ${frameBuilderImageFragment}
  },
  defaultTexture->{
    ${frameBuilderTextureFragment}
  },
`

const frameBuilderProductProfileFragment = groq`
  _id,
  sku,
  title,
  subtitle,
  width,
  minimumSize,
  maximumWidth,
  maximumHeight,
  price,
  weight,
  floatingFrame,
  minimumLargeFrameWidth,
  minimumLargeFrameHeight,
`

export const frameBuilderProfileObjectFragment = groq`
  "profiles": *[
    _type == "frameBuilderProfile" &&
    locale == $locale &&
    deactivate != true
  ]
    | order(order asc, width asc)
  {
    ${frameBuilderProfileFragment}
  },
`

export const frameBuilderProductProfileObjectFragment = groq`
  "profiles": *[
    _type == "frameBuilderProfile" &&
    locale == $locale &&
    deactivate != true
  ]
    | order(order asc, width asc)
  {
    ${frameBuilderProductProfileFragment}
  },
`
