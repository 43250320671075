import groq from 'groq'

import { frameBuilderImageFragment } from './image'

const frameBuilderGlassFragment = groq`
  ...,
  icon {
    ${frameBuilderImageFragment}
  },
`

export const frameBuilderGlassObjectFragment = groq`
  "glass": *[
    _type == "frameBuilderGlass" &&
    locale == $locale
  ]
    | order(order asc, price asc)
  {
    ${frameBuilderGlassFragment}
  },
`

export const frameBuilderProductGlassObjectFragment = groq`
  "glass": *[
    _type == "frameBuilderGlass" &&
    locale == $locale
  ]
    | order(order asc, price asc)
  {
    ...,
  },
`
