import groq from 'groq'

export const discountFragment = groq`
  ...,
  type == "bundle" => {
    products[] {
      "id": selection.id,
      "variantIds": selection.variants[selected == true].id,
    },
  },
`

export const discountQuery = groq`
  *[
    _type == "discount" &&
    locale == $locale &&
    enabled == true
  ] {
    ${discountFragment}
  }
`
