import groq from 'groq'

import { frameBuilderImageFragment } from './image'
import { frameBuilderProfileFragment } from './profile'

const frameBuilderSpacerSizeFragment = groq`
  ...,
  icon {
    ${frameBuilderImageFragment}
  },
  availableFor[]->{
    ${frameBuilderProfileFragment}
  },
`

const frameBuilderProductSpacerSizeFragment = groq`
  _id,
  sku,
  title,
  subtitle,
  description,
  thickness,
  fixedPrice,
  price,
  availableFor[],
`

const frameBuilderProductSpacerColorFragment = groq`
  _id,
  title,
`

export const frameBuilderSpacerSizeObjectFragment = groq`
  "spacerSizes": *[
    _type == "frameBuilderSpacerSize" &&
    locale == $locale
  ]
    | order(order asc, thickness asc)
  {
    ${frameBuilderSpacerSizeFragment}
  },
`

export const frameBuilderSpacerColorObjectFragment = groq`
  "spacerColors": *[
    _type == "frameBuilderSpacerColor" &&
    locale == $locale
  ]
    | order(order asc),
`

export const frameBuilderProductSpacerSizeObjectFragment = groq`
  "spacerSizes": *[
    _type == "frameBuilderSpacerSize" &&
    locale == $locale
  ]
    | order(order asc, thickness asc)
  {
    ${frameBuilderProductSpacerSizeFragment}
  },
`

export const frameBuilderProductSpacerColorObjectFragment = groq`
  "spacerColors": *[
    _type == "frameBuilderSpacerColor" &&
    locale == $locale
  ]
    | order(order asc)
  {
    ${frameBuilderProductSpacerColorFragment}
  },
`
