import groq from 'groq'

import { simplePortableTextFragment } from './content'

export const cartSettingsFragment = groq`
  ...,
  terms[] {
    ${simplePortableTextFragment}
  },
  createOrderError[] {
    ${simplePortableTextFragment}
  },
  shippingOptionsMissing[] {
    ${simplePortableTextFragment}
  },
`

export const collectionGridCartFragment = groq`
  ...,
  filter {
    ...,
    groups[] {
      ...,
      options[]->{
        ...,
        "color": color->color,
      },
    },
  },
`
