import groq from 'groq'

import { cartSettingsFragment } from './cart'
import {
  complexPortableTextFragment,
  simplePortableTextFragment,
} from './content'
import { discountFragment } from './discount'
import { imageFragment } from './image'
import { linkFragment, linkPageFragment } from './link'
import { productFragment } from './product'

const headerDesktopMenuFragment = groq`
  slug,
  items[] {
    ${linkFragment}
    dropdownItems[] {
      ${linkFragment}
    },
    featured[]->{
      ${productFragment}
    },
  },
`

const headerMobileMenuFragment = groq`
  slug,
  items[] {
    ${linkFragment}
    dropdownItems[] {
      ${linkFragment}
    },
  },
`

const headerPromoFragment = groq`
  ...,
  link->{
    ${linkPageFragment}
  },
`

const headerFragment = groq`
  menuDesktopLeft->{
    ${headerDesktopMenuFragment}
  },
  menuMobilePrimary->{
    ${headerMobileMenuFragment}
  },
  menuMobileSecondary->{
    ${headerMobileMenuFragment}
  },
`

export const newsletterFragment = groq`
  ...,
  successMsg[] {
    ${complexPortableTextFragment}
  },
  errorMsg[] {
    ${complexPortableTextFragment}
  },
  terms[] {
    ${simplePortableTextFragment}
  },
`

const footerFragment = groq`
  ...,
  menus[]->{
    title,
    items[] {
      ${linkFragment}
    },
  },
  legalMenu->{
    items[] {
      ${linkFragment}
    },
  },
`

const cookieConsentFragment = groq`
  ...,
  link->{
    ${linkPageFragment}
  },
`

const blogStringsFragment = groq`
  blogPostReadMore,
  blogPostBy,
`

const buttonStringsFragment = groq`
  buttonAddToCart,
  buttonRemove,
  buttonAccept,
  buttonLearnMore,
  buttonTryAgain,
  buttonLoadMore,
  buttonUnavailable,
  buttonWaiting,
  buttonLoading,
  buttonCreatingProduct,
  buttonAdding,
  buttonUpdating,
  buttonSubmitting,
  buttonEdit,
  buttonDelete,
  buttonCancel,
  buttonSignup,
  buttonLogin,
  buttonSeeMore,
  buttonSeeOptions,
`

const carouselStringsFragment = groq`
  carouselCounterText,
  carouselLeftArrowLabel,
  carouselRightArrowLabel,
  carouselDotLabel,
`

const cartStringsFragment = groq`
  cartTitle,
  cartClose,
  cartEmpty,
  cartVatSectionTitle,
  cartVatIdInputPlaceholder,
  cartVatIdError,
  cartInvalidVatIdError,
  cartCommentSectionTitle,
  cartCommentInputPlaceholder,
  cartSubtotal,
  cartDiscount,
  cartShipping,
  cartTotal,
  cartSubmit,
  cartAddToCartErrorMessage[] {
    ${simplePortableTextFragment}
  },
`

const formStringsFragment = groq`
  emailAddress,
  emailAddressPlaceholder,
  emailMissing,
  emailInvalid,
  firstName,
  firstNamePlaceholder,
  firstNameMissing,
  lastName,
  lastNamePlaceholder,
  lastNameMissing,
  fullName,
  fullNamePlaceholder,
  fullNameMissing,
  phoneNumber,
  phoneNumberPlaceholder,
  phoneNumberMissing,
  phoneNumberInvalid,
  company,
  companyPlaceholder,
  message,
  messagePlaceholder,
  productWaitlistSubmit,
  productWaitlistSuccess,
  productWaitlistError,
`

const pageHeaderStringsFragment = groq`
  loadingPageTitle,
  searchPlaceholder,
  searchingText,
  noResultsText,
  languageSwitch,
  cartLink,
  featuredProducts,
  skipToContent,
  goHomeLabel,
  mobileMenuLabel,
`

const productStringsFragment = groq`
  productOptionsHeading,
  productInStock,
  productLowStock,
  productOutOfStock,
  productPriceLabel,
  productPricePrefix,
  productSkuLabel,
  productIdLabel,
  productColorOptionLabel,
  productDiscountText,
  productPassepartoutHoleSize,
  productPassepartoutHoleSizeTooltip,
  productPassepartoutHoleSizePlaceholder,
  productIncreaseQuantity,
  productDecreaseQuantity,
  productEnterQuantity,
  productNotLoggedIn,
  productTaxIncludedText,
`

const combinedListingProductStringsFragment = groq`
  combinedListingLoadingText,
  combinedListingErrorText,
  combinedListingFrame,
  combinedListingFrameColor,
  combinedListingSize,
  combinedListingSizePlaceholder,
  combinedListingGlass,
  combinedListingNoGlass,
  combinedListingBackboard,
  combinedListingNoBackboard,
  combinedListingPassepartout,
  combinedListingPassepartoutHoleSize,
  combinedListingPassepartoutHoleSizeTooltip,
  combinedListingPassepartoutHoleSizePlaceholder,
  combinedListingNoPassepartout,
  combinedListingSpacer,
  combinedListingNoSpacer,
  combinedListingEmptyFrame,
  combinedListingPreviewSettingsTitle,
  combinedListingPreviewSettingsUploadLabel,
  combinedListingPreviewSettingsUploadDescription,
  combinedListingPreviewSettingsUploadError,
  combinedListingPreviewSettingsColorLabel,
`

const teamMemberStringsFragment = groq`
  teamMemberCardLinkedIn,
  teamMemberCardBlogArticles,
`

const siteStringsFragment = groq`
  ${blogStringsFragment}
  ${buttonStringsFragment}
  ${carouselStringsFragment}
  ${cartStringsFragment}
  ${formStringsFragment}
  ${pageHeaderStringsFragment}
  ${productStringsFragment}
  ${combinedListingProductStringsFragment}
  ${teamMemberStringsFragment}
`

const identityFragment = groq`
  logo {
    ${imageFragment}
  },
  invertedLogo {
    ${imageFragment}
  },
`

// Only settings that are not secret and can be used in the frontend
const publicSettingsFragment = groq`
  siteURL,
  pageAnimation,
  klaviyoAccountID,
  googleEvents,
  gtmContainerId,
  facebookEvents,
  facebookPixelId,
  facebookDomainVerification,
  facebookTestEventCode,
  shopifyEvents,
  cookieBotId,
`

export const publicSiteSettingsQuery = groq`
  *[
    _type == "generalSettings" &&
    locale == $locale
  ][0] {
    ${publicSettingsFragment}
  }
`

export const allSiteSettingsQuery = groq`
  *[
    _type == "generalSettings" &&
    locale == $locale
  ][0]
`

export const siteFragment = groq`
  "generalSettings": *[
    _type == "generalSettings" &&
    locale == $locale
  ][0] {
    ${publicSettingsFragment}
  },
  "identitySettings": *[
    _type == "identitySettings" &&
    locale == $locale
  ][0] {
    ${identityFragment}
  },
  "cartSettings": *[
    _type == "cartSettings" &&
    locale == $locale
  ][0] {
    ${cartSettingsFragment}
  },
  "blogSettings": *[
    _type == "blogSettings" &&
    locale == $locale
  ][0],
  "cookieSettings": *[
    _type == "cookieSettings" &&
    locale == $locale
  ][0] {
    ${cookieConsentFragment}
  },
  "headerSettings": *[
    _type == "headerSettings" &&
    locale == $locale
  ][0] {
    ${headerFragment}
  },
  "footerSettings": *[
    _type == "footerSettings" &&
    locale == $locale
  ][0] {
    ${footerFragment}
  },
  "seoSettings": *[
    _type == "seoSettings" &&
    locale == $locale
  ][0],
  "schemaSettings": *[
    _type == "schemaSettings" &&
    locale == $locale
  ][0],
  "promoSettings": *[
    _type == "promoSettings" &&
    locale == $locale
  ][0] {
    ${headerPromoFragment}
  },
  "strings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${siteStringsFragment}
  },
  "publicLocales": *[
    _type == "generalSettings" &&
    public == true
  ][].locale,
  "discounts": *[
    _type == "discount" &&
    locale == $locale &&
    enabled == true
  ] {
    ${discountFragment}
  },
  "allProductCount": count(*[
    _type == "product" &&
    locale == $locale &&
    wasDeleted != true &&
    isDraft != true &&
    hideFromListings != true
  ]),
  "collectionProductCounts": *[
    _type == "collection" &&
    locale == $locale
  ] {
    "slug": slug.current,
    "count": count(products),
  },
  "categoryProductCounts": *[
    _type == "productCategory" &&
    locale == $locale
  ] {
    "slug": slug.current,
    "count": count(*[
      _type == "product" &&
      category._ref == ^._id &&
      wasDeleted != true &&
      isDraft != true &&
      hideFromListings != true
    ]),
  },
`

export const siteQuery = groq`
  {
    ${siteFragment}
  }
`
