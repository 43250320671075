import groq from 'groq'

import { blogPostWithoutBodyFragment } from './blog'
import {
  complexPortableTextFragment,
  simplePortableTextFragment,
} from './content'
import { imageFragment } from './image'
import { productFragment } from './product'
import { newsletterFragment } from './site'
import { videoFragment } from './video'

const freeformBlockFragment = groq`
  ...,
  content[] {
    ${complexPortableTextFragment}
  },
`

const accordionsBlockFragment = groq`
  items[] {
    ...,
    content[] {
      ${simplePortableTextFragment}
    },
  },
`

const productCardBlockFragment = groq`
  product->{
    ${productFragment}
  },
`

const signupFormStringsFragment = groq`
  signupFirstName,
  signupFirstNamePlaceholder,
  signupFirstNameMissing,
  signupLastName,
  signupLastNamePlaceholder,
  signupLastNameMissing,
  signupCompany,
  signupCompanyPlaceholder,
  signupCompanyMissing,
  signupVatNumber,
  signupVatNumberPlaceholder,
  signupVatNumberMissing,
  signupAddress,
  signupAddressPlaceholder,
  signupAddressMissing,
  signupPostalCode,
  signupPostalCodePlaceholder,
  signupPostalCodeMissing,
  signupCity,
  signupCityPlaceholder,
  signupCityMissing,
  signupCountry,
  signupCountryPlaceholder,
  signupCountryMissing,
  signupEmail,
  signupEmailPlaceholder,
  signupEmailMissing,
  signupPhone,
  signupPhonePlaceholder,
  signupPhoneMissing,
  signupPassword,
  signupPasswordPlaceholder,
  signupPasswordMissing,
  signupRepeatPassword,
  signupRepeatPasswordPlaceholder,
  signupRepeatPasswordMissing,
  signupRepeatPasswordInvalid,
  newsletterLabel,
  signupErrorMessage[] {
    ${complexPortableTextFragment}
  },
  signupSubmit,
`

const signupFormBlockFragment = groq`
  active,
  service,
  klaviyoListID,
  "signupFormStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${signupFormStringsFragment}
  },
`

const loginFormStringsFragment = groq`
  signupEmail,
  signupEmailPlaceholder,
  signupEmailMissing,
  signupPassword,
  signupPasswordPlaceholder,
  signupPasswordMissing,
  loginErrorMessage[] {
    ${complexPortableTextFragment}
  },
  loginFailedMessage[] {
    ${complexPortableTextFragment}
  },
  loginMissingCompany[] {
    ${complexPortableTextFragment}
  },
  loginSubmit,
`

const loginFormBlockFragment = groq`
  active,
  "loginFormStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${loginFormStringsFragment}
  },
`

const passwordRecoveryFormStringsFragment = groq`
  signupEmail,
  signupEmailPlaceholder,
  signupEmailMissing,
  passwordRecoveryErrorMessage[] {
    ${complexPortableTextFragment}
  },
  passwordRecoverySuccessMessage[] {
    ${complexPortableTextFragment}
  },
  passwordRecoverySubmit,
`

const passwordRecoveryFormBlockFragment = groq`
  active,
  "passwordRecoveryFormStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${passwordRecoveryFormStringsFragment}
  },
`

const accountAddressDetailsStringsFragment = groq`
  accountAddAddressHeading,
  accountAddAddress,
  accountDefaultAddressLabel,
`

const addressFormStringsFragment = groq`
  account,
  accountAddAddressHeading,
  accountEditAddressHeading,
  accountFirstName,
  accountFirstNamePlaceholder,
  accountFirstNameMissing,
  accountLastName,
  accountLastNamePlaceholder,
  accountLastNameMissing,
  accountCompany,
  accountCompanyPlaceholder,
  accountCompanyMissing,
  accountAddressLine1,
  accountAddressLine1Placeholder,
  accountAddressLine1Missing,
  accountAddressLine2,
  accountAddressLine2Placeholder,
  accountCity,
  accountCityPlaceholder,
  accountCityMissing,
  accountCountry,
  accountCountryPlaceholder,
  accountCountryMissing,
  accountZip,
  accountZipPlaceholder,
  accountZipMissing,
  accountPhone,
  accountPhonePlaceholder,
  accountPhoneMissing,
  accountSetAsDefault,
  addressErrorMessage[] {
    ${complexPortableTextFragment}
  },
  accountAddAddress,
  accountEditAddress,
`

const accountAddressDetailsBlockFragment = groq`
  active,
  "accountAddressDetailsStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${accountAddressDetailsStringsFragment}
  },
  "addressFormStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${addressFormStringsFragment}
  },
`

const accountDetailsStringsFragment = groq`
  accountAccountDetailsHeading,
  accountViewAddresses,
  accountOrganizationDetailsHeading,
  accountOrganizationVat,
  accountOrganizationContactPerson,
`

const accountDetailsBlockFragment = groq`
  active,
  "accountDetailsStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${accountDetailsStringsFragment}
  },
`

const accountOrderListStringsFragment = groq`
  accountOrderListPaymentStatusAuthorized,
  accountOrderListPaymentStatusPaid,
  accountOrderListPaymentStatusPartiallyPaid,
  accountOrderListPaymentStatusPartiallyRefunded,
  accountOrderListPaymentStatusPending,
  accountOrderListPaymentStatusRefunded,
  accountOrderListPaymentStatusVoided,
  accountOrderListFulfillmentStatusFulfilled,
  accountOrderListFulfillmentStatusInProgress,
  accountOrderListFulfillmentStatusOnHold,
  accountOrderListFulfillmentStatusOpen,
  accountOrderListFulfillmentStatusPartiallyFulfilled,
  accountOrderListFulfillmentStatusPendingFulfillment,
  accountOrderListFulfillmentStatusRestocked,
  accountOrderListFulfillmentStatusScheduled,
  accountOrderListFulfillmentStatusUnfulfilled,
  accountOrderListHeading,
  accountOrderListOrder,
  accountOrderListDate,
  accountOrderListPaymentStatus,
  accountOrderListFulfillmentStatus,
  accountOrderListTotal,
  accountOrderDetailsHeading,
  accountOrderDetailsDate,
  accountOrderDetailsProduct,
  accountOrderDetailsSku,
  accountOrderDetailsPrice,
  accountOrderDetailsQuantity,
  accountOrderDetailsTotal,
  accountOrderDetailsSubtotal,
  accountOrderDetailsShipping,
  accountOrderDetailsTax,
  accountOrderDetailsBillingAddress,
  accountOrderDetailsShippingAddress,
  accountOrderListEmpty[] {
    ${complexPortableTextFragment}
  },
`

const accountOrderListBlockFragment = groq`
  active,
  "accountOrderListStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${accountOrderListStringsFragment}
  },
`

const collectionCarouselBlockFragment = groq`
  collection->{
    products[]->{
      ${productFragment}
    },
  },
`

const blogPostCardBlockFragment = groq`
  options,
  post->{
    ${blogPostWithoutBodyFragment}
  },
`

const linkBlockFragment = groq`
  title,
  link->{
    _type,
    slug,
  },
  image {
    ${imageFragment}
  },
`

export const contentCarouselBlockFragment = groq`
  title,
  items[] {
    _key,
    _type,
    _type == "freeform" => {
      ${freeformBlockFragment}
    },
    _type == "linkBlock" => {
      ${linkBlockFragment}
    },
    _type == "photo" => {
      ${imageFragment}
    },
    _type == "product" => {
      "product": *[
        _type == "product" &&
        _id == ^._ref
      ][0] {
        ${productFragment}
      },
    },
    _type == "blogPost" => {
      "blogPost": *[
        _type == "blogPost" &&
        _id == ^._ref
      ][0] {
        ${blogPostWithoutBodyFragment}
      },
    },
    _type == "video" => {
      ${videoFragment}
    },
  },
`

const cookieDeclarationFragment = groq`
  active,
  "cookieBotId": *[
    _type == "generalSettings" &&
    locale == $locale
  ][0].cookieBotId,
`

export const ctaFragment = groq`
  content[] {
    ${complexPortableTextFragment}
  },
  image {
    ${imageFragment}
  },
`

export const demoFormFragment = groq`
  ...,
  successMsg[] {
    ${complexPortableTextFragment}
  },
  errorMsg[] {
    ${complexPortableTextFragment}
  },
  terms[] {
    ${simplePortableTextFragment}
  },
  newsletterStatement[] {
    ${simplePortableTextFragment}
  },
`

const contactFormFragment = groq`
  ...,
  successMsg[] {
    ${complexPortableTextFragment}
  },
  errorMsg[] {
    ${complexPortableTextFragment}
  },
  terms[] {
    ${simplePortableTextFragment}
  },
`

const iframeBlockFragment = groq`
  ...,
`

const teamMemberCardFragment = groq`
  teamMember->{
    ...,
    blogAuthor->{
      slug,
    },
    photo {
      ${imageFragment}
    },
  },
`

const uspFragment = groq`
  ...,
  icon {
    ${imageFragment}
  },
`

const baseBlockFragment = groq`
  _key,
  _type,
  _type == "freeform" => {
    ${freeformBlockFragment}
  },
  _type == "accordions" => {
    ${accordionsBlockFragment}
  },
  _type == "productCard" => {
    ${productCardBlockFragment}
  },
  _type == "signupForm" => {
    ${signupFormBlockFragment}
  },
  _type == "loginForm" => {
    ${loginFormBlockFragment}
  },
  _type == "passwordRecoveryForm" => {
    ${passwordRecoveryFormBlockFragment}
  },
  _type == "accountDetails" => {
    ${accountDetailsBlockFragment}
  },
  _type == "accountAddressDetails" => {
    ${accountAddressDetailsBlockFragment}
  },
  _type == "accountOrderList" => {
    ${accountOrderListBlockFragment}
  },
  _type == "video" => {
    ${videoFragment}
  },
  _type == "collectionCarousel" => {
    ${collectionCarouselBlockFragment}
  },
  _type == "newsletter" => {
    ${newsletterFragment}
  },
  _type == "blogPostCard" => {
    ${blogPostCardBlockFragment}
  },
  _type == "contentCarousel" => {
    ${contentCarouselBlockFragment}
  },
  _type == "cookieDeclaration" => {
    ${cookieDeclarationFragment}
  },
  _type == "cta" => {
    ${ctaFragment}
  },
  _type == "demoForm" => {
    ${demoFormFragment}
  },
  _type == "contactForm" => {
    ${contactFormFragment}
  },
  _type == "iframe" => {
    ${iframeBlockFragment}
  },
  _type == "teamMemberCard" => {
    ${teamMemberCardFragment}
  },
  _type == "linkBlock" => {
    ${linkBlockFragment}
  },
  _type == "usp" => {
    ${uspFragment}
  },
 `

const predefinedContentBlockFragment = groq`
  "contentBlocks": contentBlock->contentBlocks[] {
    ${baseBlockFragment}
  },
`

export const blockFragment = groq`
  ${baseBlockFragment}
  _type == 'predefinedContentBlock' => {
    ${predefinedContentBlockFragment}
  },
 `
