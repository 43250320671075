import { type SanityClient } from '@sanity/client'

import {
  accountAddressPageQuery,
  accountPageQuery,
  allProductSlugsQuery,
  allSlugsQuery,
  cartPageQuery,
  collectionPageQuery,
  crawlingBlockedPagesQuery,
  customPageQuery,
  errorPageQuery,
  homePageQuery,
  loginPageQuery,
  orderSuccessPageQuery,
  passwordRecoveryPageQuery,
  productCategoryPageQuery,
  productPageQuery,
  shopPageQuery,
  signupPageQuery,
  signupSuccessPageQuery,
  sitemapPagesQuery,
  sitemapStaticPagesQuery,
} from '@data/sanity/queries/page'
import {
  type SanityAccountAddressPageQuery,
  type SanityAccountPageQuery,
  type SanityAllProductSlugs,
  type SanityAllSlugs,
  type SanityCartPageQuery,
  type SanityCollectionPageQuery,
  type SanityCrawlingBlockedPagesQuery,
  type SanityCustomPageQuery,
  type SanityErrorPageQuery,
  type SanityHomePageQuery,
  type SanityLoginPageQuery,
  type SanityOrderSuccessPageQuery,
  type SanityPasswordRecoveryPageQuery,
  type SanityProductCategoryPageQuery,
  type SanityProductPageQuery,
  type SanityShopPageQuery,
  type SanitySignupPageQuery,
  type SanitySignupSuccessPageQuery,
  type SanitySitemapPagesQuery,
  type SanitySitemapStaticPagesQuery,
} from '@data/sanity/queries/types/page'
import {
  type SanityBlogAuthorSlugQueryParameter,
  type SanityBlogCategorySlugQueryParameter,
  type SanityBlogPostSlugQueryParameter,
  type SanityCollectionSlugQueryParameter,
  type SanityLocaleQueryParameter,
  type SanityLocalesQueryParameter,
  type SanityPageSlugsQueryParameter,
  type SanityProductCategorySlugQueryParameter,
  type SanityProductSlugQueryParameter,
  type SanityTypeQueryParameter,
  type SanityTypesQueryParameter,
} from '@data/sanity/queries/types/parameters'
import { fetchQuery } from './client'
import { type SitemapPageType } from '@lib/routes'

export type SanityModuleQueryParameters = SanityBlogAuthorSlugQueryParameter &
  SanityBlogCategorySlugQueryParameter &
  SanityBlogPostSlugQueryParameter &
  SanityCollectionSlugQueryParameter &
  SanityProductCategorySlugQueryParameter &
  SanityProductSlugQueryParameter

export const defaultModuleQueryParameters: SanityModuleQueryParameters = {
  blogAuthorSlug: null,
  blogCategorySlug: null,
  blogPostSlug: null,
  collectionSlug: null,
  productCategorySlug: null,
  productSlug: null,
}

const sitemapStaticPageTypes: SitemapPageType[] = [
  'accountAddressPage',
  'accountPage',
  'blogPage',
  'cartPage',
  'homePage',
  'loginPage',
  'passwordRecoveryPage',
  'shopPage',
  'signupPage',
]

/**
 * Fetches all document slugs in all locales.
 */
export const getAllSlugs = async (sanityClient: SanityClient, type: string) =>
  fetchQuery<SanityAllSlugs, SanityTypeQueryParameter>(
    sanityClient,
    allSlugsQuery,
    {
      type,
    }
  )

/**
 * Fetches all product slugs in all locales.
 */
export const getAllProductSlugs = async (sanityClient: SanityClient) =>
  fetchQuery<SanityAllProductSlugs>(sanityClient, allProductSlugsQuery)

/**
 * Fetches pages for sitemap.
 */
export const getSitemapPages = async (
  sanityClient: SanityClient,
  locales: string[],
  type: SitemapPageType
) =>
  fetchQuery<
    SanitySitemapPagesQuery,
    SanityTypeQueryParameter & SanityLocalesQueryParameter
  >(sanityClient, sitemapPagesQuery, {
    locales,
    type,
  })

/**
 * Fetches static pages for sitemap.
 */
export const getSitemapStaticPages = async (
  sanityClient: SanityClient,
  locales: string[]
) =>
  fetchQuery<
    SanitySitemapStaticPagesQuery,
    SanityLocalesQueryParameter & SanityTypesQueryParameter
  >(sanityClient, sitemapStaticPagesQuery, {
    locales,
    types: sitemapStaticPageTypes,
  })

/**
 * Fetches crawling-blocked pages for robots.
 */
export const getCrawlingBlockedPages = async (sanityClient: SanityClient) =>
  fetchQuery<SanityCrawlingBlockedPagesQuery>(
    sanityClient,
    crawlingBlockedPagesQuery
  )

/**
 * Fetches the shop index page.
 */
export const getShopIndexPage = async (
  sanityClient: SanityClient,
  locale: string
) =>
  fetchQuery<
    SanityShopPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, shopPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the cart page.
 */
export const getCartPage = async (sanityClient: SanityClient, locale: string) =>
  fetchQuery<
    SanityCartPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, cartPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the order success page.
 */
export const getOrderSuccessPage = async (
  sanityClient: SanityClient,
  locale: string
) =>
  fetchQuery<
    SanityOrderSuccessPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, orderSuccessPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the home page.
 */
export const getHomePage = async (sanityClient: SanityClient, locale: string) =>
  fetchQuery<
    SanityHomePageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, homePageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the error page.
 */
export const getErrorPage = async (
  sanityClient: SanityClient,
  locale: string
) =>
  fetchQuery<
    SanityErrorPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, errorPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches a specific page based on slug.
 */
export const getPage = async (
  sanityClient: SanityClient,
  locale: string,
  slug: string
) =>
  fetchQuery<
    SanityCustomPageQuery,
    SanityLocaleQueryParameter &
      SanityPageSlugsQueryParameter &
      SanityModuleQueryParameters
  >(sanityClient, customPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
    pageSlugs: [`/${slug}`, slug, `/${slug}/`],
  })

/**
 * Fetches the signup page.
 */
export const getSignupPage = async (
  sanityClient: SanityClient,
  locale: string
) =>
  fetchQuery<
    SanitySignupPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, signupPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the signup success page.
 */
export const getSignupSuccessPage = async (
  sanityClient: SanityClient,
  locale: string
) =>
  fetchQuery<
    SanitySignupSuccessPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, signupSuccessPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the login page.
 */
export const getLoginPage = async (
  sanityClient: SanityClient,
  locale: string
) =>
  fetchQuery<
    SanityLoginPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, loginPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the password recovery page.
 */
export const getPasswordRecoveryPage = async (
  sanityClient: SanityClient,
  locale: string
) =>
  fetchQuery<
    SanityPasswordRecoveryPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, passwordRecoveryPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the account page.
 */
export const getAccountPage = async (
  sanityClient: SanityClient,
  locale: string
) =>
  fetchQuery<
    SanityAccountPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, accountPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the account address page.
 */
export const getAccountAddressPage = async (
  sanityClient: SanityClient,
  locale: string
) =>
  fetchQuery<
    SanityAccountAddressPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, accountAddressPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches a collection page based on its slug.
 */
export const getCollectionPage = async (
  sanityClient: SanityClient,
  locale: string,
  collectionSlug: string
) =>
  fetchQuery<
    SanityCollectionPageQuery,
    SanityLocaleQueryParameter &
      SanityCollectionSlugQueryParameter &
      SanityModuleQueryParameters
  >(sanityClient, collectionPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
    collectionSlug,
  })

/**
 * Fetches a product catergory page based on its slug.
 */
export const getProductCategoryPage = async (
  sanityClient: SanityClient,
  locale: string,
  productCategorySlug: string
) =>
  fetchQuery<
    SanityProductCategoryPageQuery,
    SanityLocaleQueryParameter &
      SanityProductCategorySlugQueryParameter &
      SanityModuleQueryParameters
  >(sanityClient, productCategoryPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
    productCategorySlug,
  })

/**
 * Fetches product page data based on product slug.
 */
export const getProductPage = async (
  sanityClient: SanityClient,
  locale: string,
  productSlug: string
) =>
  fetchQuery<
    SanityProductPageQuery,
    SanityLocaleQueryParameter &
      SanityProductSlugQueryParameter &
      SanityModuleQueryParameters
  >(sanityClient, productPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
    productSlug,
  })
