import groq from 'groq'

import { frameBuilderModuleFragment } from '@frame-builder/data/sanity/queries'
import {
  blockFragment,
  contentCarouselBlockFragment,
  ctaFragment,
} from './blocks'
import { blogPostWithoutBodyFragment } from './blog'
import { cartSettingsFragment, collectionGridCartFragment } from './cart'
import { complexPortableTextFragment } from './content'
import { imageFragment } from './image'
import { linkPageFragment } from './link'
import {
  productCombinedListingConfigurationFragment,
  productFragment,
} from './product'
import { muxVideoFragment, videoFragment } from './video'

// Blog author post grid Module
const blogAuthorPostGridModuleFragment = groq`
  options,
  $blogAuthorSlug != null => {
    "posts": *[
      _type == "blogPost" &&
      type != "template" &&
      locale == $locale &&
      author->slug.current == $blogAuthorSlug
    ]
      | order(_createdAt desc)
      | order(date desc)
    {
      ${blogPostWithoutBodyFragment}
    },
  },
`

// Blog category post grid module
const blogCategoryPostGridModuleFragment = groq`
  options,
  $blogCategorySlug != null => {
    "posts": *[
      _type == "blogPost" &&
      type != "template" &&
      locale == $locale &&
      categories[]->slug.current match $blogCategorySlug
    ]
      | order(_createdAt desc)
      | order(date desc)
    {
      ${blogPostWithoutBodyFragment}
    },
  },
`

// Blog post body module
const blogPostBodyModuleFragment = groq`
  content[] {
    ${complexPortableTextFragment}
  },
`

// Blog post grid module
const blogPostGridModuleFragment = groq`
  options,
  "posts": *[
    _type == "blogPost" &&
    type != "template" &&
    locale == $locale &&
    (!defined(^.author) || author->slug.current == ^.author->slug.current) &&
    (!defined(^.category) || categories[]->slug.current match ^.category->slug.current)
  ]
    | order(_createdAt desc)
    | order(date desc)
  {
    ${blogPostWithoutBodyFragment}
  },
`

// Blog post header module
const blogPostHeaderModuleFragment = groq`
  options,
  $blogPostSlug != null => {
    "post": *[
      _type == "blogPost" &&
      type != "template" &&
      locale == $locale &&
      slug.current == $blogPostSlug
    ]
      | order(_updatedAt desc)
    [0] {
      ${blogPostWithoutBodyFragment}
    },
  },
`

// Blog post item module
const blogPostItemModuleFragment = groq`
  ...,
  post->{
    ${blogPostWithoutBodyFragment}
  },
`

// Blog post read more module
const blogPostReadMoreModuleFragment = groq`
  $blogPostSlug != null => {
    "posts": *[
      _type == "blogPost" &&
      type != "template" &&
      locale == $locale &&
      slug.current != $blogPostSlug
    ] {
      "matchingCategoryCount": count((categories[]._ref)[
        @ in *[
          _type == "blogPost" &&
          type != "template" &&
          locale == $locale &&
          slug.current == $blogPostSlug
        ]
          | order(_updatedAt desc)
        [0].categories[]._ref
      ]),
      ${blogPostWithoutBodyFragment}
    }
      | order(_createdAt desc)
      | order(date desc)
      | order(matchingCategoryCount desc)
    [0...5],
  },
`

// Cart form module
const cartFormModuleFragment = groq`
  active,
  "cartSettings": *[
    _type == "cartSettings" &&
    locale == $locale
  ][0] {
    ${cartSettingsFragment}
  },
`

// Collection grid
const collectionStringsFragment = groq`
  collectionProducts,
  collectionProductCount,
  collectionSortLabel,
  collectionSortDescription,
  collectionShowFiltersLabel,
  collectionFilters,
  collectionRemoveFilter,
  collectionClearAllLabel,
  collectionClearFiltersLabel,
  collectionFilterResultsLabel,
  collectionFilterNoResultsLabel,
  collectionFilterModalClose,
  collectionFilterNoResults[] {
    ${complexPortableTextFragment}
  },
`

const collectionGridModuleFragment = groq`
  active,
  $collectionSlug != null => {
    "collection": *[
      _type == "collection" &&
      slug.current == $collectionSlug &&
      locale == $locale
    ][0] {
      title,
      "featuredProductIds": products[]->productID,
      "products": *[
        _type == "product" &&
        locale == $locale &&
        _id in ^.products[]._ref &&
        wasDeleted != true &&
        isDraft != true &&
        hideFromListings != true
      ] {
        ${productFragment}
      },
      "productIds": products[]._ref,
    },
  },
  $productCategorySlug != null => {
    "productCategory": *[
      _type == "productCategory" &&
      slug.current == $productCategorySlug &&
      locale == $locale
    ][0] {
      title,
      "products": *[
        _type == "product" &&
        locale == $locale &&
        category._ref == ^._id &&
        wasDeleted != true &&
        isDraft != true &&
        hideFromListings != true
      ] {
        ${productFragment}
      },
    },
  },
  $collectionSlug == null && $productCategorySlug == null => {
    "shop": *[
      _type == "shopPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      "featuredProductIds": featuredProducts[]->productID,
      "products": *[
        _type == "product" &&
        locale == $locale &&
        wasDeleted != true &&
        isDraft != true &&
        hideFromListings != true
      ]
        | order(title asc)
      {
        ${productFragment}
      },
    },
  },
  "cartSettings": *[
    _type == "cartSettings" &&
    locale == $locale
  ][0] {
    ${collectionGridCartFragment}
  },
  "collectionStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${collectionStringsFragment}
  },
`

// Content carousel
const contentCarouselModuleFragment = contentCarouselBlockFragment

// CTA
const ctaModuleFragment = ctaFragment

// Divider photo module
const dividerPhotoModuleFragment = groq`
  photo {
    ${imageFragment}
  },
`

// Grid module
const gridModuleFragment = groq`
  ...,
  columns[] {
    ...,
    sizes[] {
      ...,
    },
    blocks[] {
      ${blockFragment}
    },
  },
`

// Hero module
const heroModuleFragment = groq`
  bgType,
  content[] {
    ${complexPortableTextFragment}
  },
  bgType == "photo" => {
    photos {
      mobilePhoto {
        ${imageFragment}
      },
      desktopPhoto {
        ${imageFragment}
      },
    },
  },
  bgType == "lottie-animation" => {
    "lottieAssetUrl": lottieFile.asset->url,
  },
  bgType == "mux-video" => {
    ${muxVideoFragment}
  },
`

// Image and text module
const imageAndTextModuleFragment = groq`
  image {
    ${imageFragment}
  },
  textContent[] {
    ${complexPortableTextFragment}
  },
  textPosition,
  background,
`

// Logo grid module
const logoGrid = groq`
  title,
  logos[] {
    ${imageFragment}
  },
`

// Marquee module
const marqueeModuleFragment = groq`
  ...,
  content[] {
    ${complexPortableTextFragment}
  },
  items[] {
    ...,
    _type == "photo" => {
      ${imageFragment}
    },
    _type == "product" => {
      "product": *[
        _type == "product" &&
        _id == ^._ref
      ][0] {
        ${productFragment}
      },
    },
  },
`

// Pricing plans module
const pricingPlansModuleFragment = groq`
  plans[] {
    ...,
    button {
      ...,
      page->{
        ${linkPageFragment}
      },
    },
  },
`

// Product hero module
const productHeroModuleFragment = groq`
  active,
  "product": *[
    _type == "product" &&
    locale == $locale &&
    slug.current == $productSlug &&
    wasDeleted != true &&
    isDraft != true
  ]
    | order(_updatedAt desc)
  [0] {
    ${productFragment}
    combinedListingProductType == "frame" => {
      ${productCombinedListingConfigurationFragment}
    },
  },
`

// Team members grid module
const teamMembersGridModuleFragment = groq`
  ...,
  teamMembers[]->{
    ...,
    blogAuthor->{
      slug,
    },
    photo {
      ${imageFragment}
    },
  },
`

const baseModuleFragment = groq`
  _key,
  _type,
  _type == "blogAuthorPostGrid" => {
    ${blogAuthorPostGridModuleFragment}
  },
  _type == "blogCategoryPostGrid" => {
    ${blogCategoryPostGridModuleFragment}
  },
  _type == "blogPostBody" => {
    ${blogPostBodyModuleFragment}
  },
  _type == "blogPostGrid" => {
    ${blogPostGridModuleFragment}
  },
  _type == "blogPostHeader" => {
    ${blogPostHeaderModuleFragment}
  },
  _type == 'blogPostItem' => {
    ${blogPostItemModuleFragment}
  },
  _type == "blogPostReadMore" => {
    ${blogPostReadMoreModuleFragment}
  },
  _type == "cartForm" => {
    ${cartFormModuleFragment}
  },
  _type == "collectionGrid" => {
    ${collectionGridModuleFragment}
  },
  _type == "contentCarousel" => {
    ${contentCarouselModuleFragment}
  },
  _type == "cta" => {
    ${ctaModuleFragment}
  },
  _type == "dividerPhoto" => {
    ${dividerPhotoModuleFragment}
  },
  _type == "frameBuilder" => {
    ${frameBuilderModuleFragment}
  },
  _type == "grid" => {
    ${gridModuleFragment}
  },
  _type == "hero" => {
    ${heroModuleFragment}
  },
  _type == 'imageAndText' => {
    ${imageAndTextModuleFragment}
  },
  _type == 'logoGrid' => {
    ${logoGrid}
  },
  _type == "marquee" => {
    ${marqueeModuleFragment}
  },
  _type == "pricingPlans" => {
    ${pricingPlansModuleFragment}
  },
  _type == "productHero" => {
    ${productHeroModuleFragment}
  },
  _type == "teamMembersGrid" => {
    ${teamMembersGridModuleFragment}
  },
  _type == "video" => {
    ${videoFragment}
  },
`

const predefinedPageModuleModuleFragment = groq`
  "pageModules": pageModule->pageModules[] {
    ${baseModuleFragment}
  },
`

export const moduleFragment = groq`
  ${baseModuleFragment}
  _type == 'predefinedPageModule' => {
    ${predefinedPageModuleModuleFragment}
  },
`
